import { Component } from '@angular/core';

@Component({
    selector: 'app-token-invalido',
    templateUrl: './token-invalido.component.html',
    styleUrls: ['./token-invalido.component.css'],
    standalone: false
})
export class TokenInvalidoComponent {

}
