import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { PoModule } from '@po-ui/ng-components';
import { PoPageLoginModule, PoTemplatesModule } from '@po-ui/ng-templates';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { httpInterceptorProviders } from './http-interceptors';
import { NovaSenhaComponent } from './nova-senha/nova-senha.component';
import { TokenInvalidoComponent } from './token-invalido/token-invalido.component';

import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';

registerLocaleData(ptBr);

@NgModule({ declarations: [AppComponent, NovaSenhaComponent, TokenInvalidoComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        PoModule,
        RouterModule.forRoot([]),
        PoTemplatesModule,
        FormsModule,
        PoPageLoginModule,
        BrowserAnimationsModule,
        FullCalendarModule], providers: [
        httpInterceptorProviders,
        { provide: LOCALE_ID, useValue: 'pt-br' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
